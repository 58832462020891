// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-article-template-tsx": () => import("./../../../slipway-theme/packages/slipway-theme-gatsby/src/templates/article.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-article-template-tsx" */),
  "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-articles-template-tsx": () => import("./../../../slipway-theme/packages/slipway-theme-gatsby/src/templates/articles.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-articles-template-tsx" */),
  "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-page-template-tsx": () => import("./../../../slipway-theme/packages/slipway-theme-gatsby/src/templates/page.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-page-template-tsx" */),
  "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-specialist-booking-template-tsx": () => import("./../../../slipway-theme/packages/slipway-theme-gatsby/src/templates/specialist-booking.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-specialist-booking-template-tsx" */),
  "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-specialist-template-tsx": () => import("./../../../slipway-theme/packages/slipway-theme-gatsby/src/templates/specialist.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-specialist-template-tsx" */),
  "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-specialists-template-tsx": () => import("./../../../slipway-theme/packages/slipway-theme-gatsby/src/templates/specialists.template.tsx" /* webpackChunkName: "component---slipway-theme-packages-slipway-theme-gatsby-src-templates-specialists-template-tsx" */)
}

